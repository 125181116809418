import Finder, { router } from "@fbinhouse/volvo-react-vcs-tyrefinder-v2";
import "@fbinhouse/volvo-react-vcs-tyrefinder-v2/dist/styles.css";
import { useParams } from "@reach/router";
import { useState } from "react";

export const Tyres = () => {
    const [moduleRoute, setModuleRoute] = useState("/");
    const { branchCode, bookTyreChangeLink } = useParams();

    const onUrlChanged = (_url) => {
        window.scrollTo(0, 0);

        setModuleRoute(_url);
    };

    return (
        <router.VDWRoutingContainer
            url={moduleRoute}
            onUrlChanged={onUrlChanged}
        >
            <Finder
                branchCode={branchCode}
                bookTyreChangeLink={bookTyreChangeLink}
                offsetTopSm={0}
                offsetTopLg={0}
            />
        </router.VDWRoutingContainer>
    );
};
