import { Router } from "@reach/router";
import { StyleProvider, ThemeProvider } from "vcc-ui";
import volvo from "vcc-ui/lib/themes/volvo";
import { Tyres } from "./Tyres";

const pathPrefix = "https://pkgs.gvcs.fbinhouse.se/vcc-ui/1.2.1/assets";

export const App = () => {
    return (
        <StyleProvider>
            <ThemeProvider
                theme={{
                    ...volvo,
                    fontsPath: pathPrefix + "/fonts/",
                    logoImagesPath: pathPrefix + "/images/",
                }}
            >
                <Router>
                    <Tyres path="/:branchCode/*bookTyreChangeLink" />
                </Router>
            </ThemeProvider>
        </StyleProvider>
    );
};
